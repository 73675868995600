import { Packages, Videos } from "@prisma/client"
import uuid from "uuid"
import { cardResponse } from "~/routes/api+/cards"

export const dotIdentify = (email: string) => {
  if (!window.dmPt) return
  window.dmPt("identify", email)
}

export const dotTrack = () => {
  if (!window.dmPt) return
  window.dmPt("track")
}

export const dotCart = ({
  cookies,
  setCookie,
  removeCookie,
  pkg,
  additionalPkg,
  video,
  complete,
}: {
  cookies: { [x: string]: any }
  setCookie: (name: any, value: any, options?: any) => void
  removeCookie: (name: any, options?: any) => void
  pkg?: Packages
  additionalPkg?: Packages
  video?: Videos
  complete?: boolean
}) => {
  let cart = {
    id: cookies.cart?.id || uuid.v4(),
    url: pkg ? window.location.toString() : cookies.cart?.url,
    pkg: pkg || cookies.cart?.pkg,
    additionalPkg: additionalPkg || cookies.cart?.additionalPkg,
    video: pkg ? video : cookies.cart?.video,
    created: cookies.cart?.created || new Date().toISOString(),
  }

  if (!window.dmPt) return

  setCookie("cart", cart, { path: "/", maxAge: 60 * 60 * 24 * 7 })

  let lineItems = [
    {
      sku: cart.pkg?.PackageID,
      name: cart.pkg?.Name,
      unitPrice: cart.pkg?.Price,
      quantity: 1,
      totalPrice: cart.pkg?.Price,
      imageUrl: cart.video && cart.video.Stills && cart.video.Stills[0],
      video: cart.video && cart.video.VideoTitle,
      star: cart.video && cart.video.StarName,
      productUrl: cart.url,
    },
  ]

  if (cart.additionalPkg) {
    lineItems.push({
      sku: cart.additionalPkg.PackageID,
      name: cart.additionalPkg.Name,
      unitPrice: cart.additionalPkg.Price,
      quantity: 1,
      totalPrice: cart.additionalPkg.Price,
      imageUrl: cart.video && cart.video.Stills && cart.video.Stills[0],
      video: cart.video && cart.video.VideoTitle,
      star: cart.video && cart.video.StarName,
      productUrl: cart.url,
    })
  }

  const total = lineItems.reduce((acc, item) => acc + item.totalPrice, 0)

  window.dmPt("cartInsight", {
    // "programID": 12345,
    // "cartDelay": 25,
    cartID: cart.id,
    cartPhase: complete ? "ORDER_COMPLETE" : "CUSTOMER_LOGIN",
    currency: "USD",
    subtotal: total,
    shipping: 0,
    taxAmount: 0,
    grandTotal: total,
    cartUrl: cart.url,
    createDate: cart.created,
    modifieddate: cart.created,
    lineItems,
  })

  if (complete) {
    removeCookie("cart", { path: "/", maxAge: 60 * 60 * 24 * 7 })
  }
}
